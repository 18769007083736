const en= {
    "App": {
        "common": {
            "yes": "Yes",
            "no": "No",
            "promptInstall1": "To install this WebApp on your iPhone\/iPad, click on ",
            "promptInstall2": " then Add to your home screen.",
            "en": "EN",
            "fr": "FR",
            "language": {
                "fr": "French",
                "en": "English"
            }
        },
        "screens": {
            "TopoList": {
                "title": "Guide choice",
                "no-topo": "No guide available yet\u2026",
                "no-topo-desc": "Connection error, check that you are connected to the internet."
            },
            "LoginScreen": {
                "title": "Log in"
            },
            "UserScreen": {
                "title": "My account"
            },
            "OrderScreen": {
                "title": "My orders"
            },
            "CartScreen": {
                "h1Title": {
                    "empty": "Your basket is empty",
                    "yourCart": "Your basket"
                },
                "message": {
                    "totalVat": "Sub-total",
                    "shippingCostVat": "Shipping costs",
                    "shippingCostVatHelp": "Click below for abroad tracked shipping (+10€)",
                    "validationButton": "Confirm my basket",
                    "promotionTable": {
                        "title": "Discount levels",
                        "subtitle": "These discounts only apply to digital items (not hard copy guidebooks)",
                        "table": {
                            "minAmount": "Minimum amount",
                            "reductionPercent": "Percentage discount"
                        }
                    }
                },
                "addedToCart": " added to basket!",
                "removedFromCart": " removed from basket!",
                "title": "Your basket"
            },
            "UserInformationUpdateScreen": {
                "h1Title": "About me"
            }
        },
        "footer": {
            "label": "\u00a9 OmegaRoc 2020 - All rights reserved"
        },
        "components": {
            "PresentationComponent": {
                "button": "Info"
            },
            "AddressListComponent": {
                "title": "My addresses",
                "message": {
                    "confirmDelete": "Are you sure you want to delete this address?",
                    "confirmDefault": "Are you sure you want to select this as your default address?",
                    "defaultAddress": "Default address",
                    "defaultButtonText": "Set as default",
                    "deleteAddressButtonText": "Delete",
                    "addAddressButtonText": "Add"
                },
                "button": {
                    "update": "Modify"
                }
            },
            "BillingComponent": {
                "h1Title": "Invoice information",
                "message": {
                    "billingAddress": "Invoice address",
                    "shippingAddress": "Shipping address",
                    "differentShippingAddress": "Shipping address different to invoice address?"
                },
                "button": {
                    "finalizeCommandButton": "Finalise my order"
                }
            },
            "CompleteComponent": {
                "h1Title": {
                    "success": "Your order has been completed successfully",
                    "cancelled": "Your order has not been completed successfully"
                },
                "success": {
                    "ctaLabel": "My routes",
                    "title": "Your order is completed",
                    "message": "Your guidebook is available in ''My routes''"
                },
                "cancelled": {
                    "ctaLabel": "My basket",
                    "title": "Your order has not been completed successfully",
                    "message": "Your order has not been completed successfully"
                }
            },
            "PaymentComponent": {
                "h1Title": "Summary and payment",
                "message": {
                    "totalWithoutReduction": "Total before discount",
                    "reductionAmount": "Discount {reductionAmount}% (inc. VAT)",
                    "shippingCostVat": "Shipping costs",
                    "shippingCostVatHelp": "Click below for abroad tracked shipping (+10€)",
                    "shippingMethodExpress": "Abroad tracked shipping (+10€)",
                    "totalVat": "Total (inc. VAT)"
                },
                "button": {
                    "finalizeCommandButton": "Finalise my order",
                    "payWithCB": "Pay by debit \/ credit card"
                }
            },
            "OrderListComponent": {
                "h1Title": "My orders",
                "message": {
                    "seeMoreButtonText": "Open order",
                    "noOrder": "You have not placed any orders yet."
                }
            },
            "OrderDetailsComponent": {
                "h1Title": "My order details",
                "orderNumber": "Order #{{number}}"
            },
            "PasswordUpdateComponent": {
                "message": {
                    "changePassword": "Change my password"
                }
            },
            "UserInformationUpdateComponent": {
                "h1Title": "Change my information",
                "myAccount": "My ID",
                "message": {
                    "typeInformations": "Enter your name and surname"
                }
            }
        },
        "menu": {
            "myroute": "My routes",
            "search": "Search",
            "guidebook": "Guidebooks",
            "cart": "Basket",
            "ticking": "My To do list \/ Tick list",
            "account": "About me",
            "adresses": "My addresses",
            "orders": "My orders",
            "logout": "Log out",
            "loging-out": "Logging out..."
        }
    },
    "Navbar": {
        "back": "Go back",
        "account": "My account",
        "close": "Close",
        "guidebooks": "Guidebooks",
        "cart": "My basket",
        "logout": "Log out",
        "menu": "Menu",
        "ticking": "My To do list \/ Tick list",
        "myroutes": "My routes",
        "search": "Search",
        "searchLabel": "Search",
        "Facebook": "Facebook",
        "Omegaroc": "OmegaRoc",
        "FondEquipement": "Bolting fund",
        "cgv": "General conditions",
        "Aboutus": " About the app",
        "EthicalGuidebook": "Ethical guidebooks",
        "userBook": "User guide",
        "saveRoute": {
            "guest": "Please log in to save your routes",
            "label": "My saved routes",
            "cliff": "Sport crags",
            "multipitch": "Multi-pitch routes",
            "clearAll": "Delete all",
            "empty": "You have not saved any routes.",
            "bookmarkEmpty": "No route bookmarked",
            "delete": {
                "confirm": "Confirm delete",
                "confirmAll": "Confirm delete of ALL saved routes"
            },
            "activationOver": "This route is not active anymore."
        }
    },
    "FlashMessage": {
        "confirm": "Got it!"
    },
    "TopoScreen": {
        "unlock": {
            "price": "This guidebook is available for purchase.",
            "isQuestionable": "If you already have the hard copy guidebook, you can activate it for free by answering a question.",
            "code": "You can activate it by answering a question.",
            "endValidationTime": "This guidebook activation expired in the following days. Unlock it now!",
            "cta": "Re-activate your guidebook"
        },
        "description": "See all guidebook content in the dropdown list below."
    },
    "TopoAddToCart": {
        "buyDigitalVersion": "Buy digital version",
        "accessGuidebook": "Go to guidebook",
        "unlockGuidebook": "Activate",
        "unlockDigitalGuidebook": "Activate the digital version",
        "digitalVersionOnly": "Available as digital version only",
        "buyGuidebook": "Buy both hard copy and digital version"
    },
    "TopoValidationScreen": {
        "description": "To access the digital guidebook {{label}} content you must own the hard copy and be registered. To register, you must enter your email address and answer the question below. <br\/>",
        "popin": {
            "title": {
                "success": "Correct answer",
                "failed": "Incorrect answer"
            },
            "description": {
                "success": "Access all the content of the guidebook in 'My Routes' (in the bottom toolbar)"
            },
            "error": {
                "title": "Incorrect answer",
                "message": "The activation code is not correct"
            },
            "button": {
                "ok": "Ok",
                "typeCode": "Access the guidebook"
            }
        },
        "form": {
            "input": {
                "email": {
                    "label": "Enter\u00a0your\u00a0e-mail address",
                    "placeholder": "Email"
                },
                "edition": {
                    "label": "Guide edition:"
                },
                "reponse": {
                    "placeholder": "Answer"
                },
                "submit": {
                    "text": "Valider"
                }
            },
            "help": "An activation message will be sent to the e-mail address that you have provided."
        },
        "title": "Activate a topo",
        "alert": {
            "guestMessage": "Log in to activate a guidebook.",
            "guestCta": "Log in",
            "missingQuestion": "No questions available to activate this guidebook."
        }
    },
    "ActivationScreen": {
        "form": {
            "title": "Enter an activation code:",
            "code": {
                "label": "Activation code"
            },
            "submit": "Activate"
        },
        "title": {
            "default": "Guide choice",
            "error": "An error has occured"
        },
        "link": {
            "label": "To receive a new activation email,",
            "cta": "click here",
            "activationLabel": "To enter the activation code again,",
            "activationCta": "click here"
        },
        "error": {
            "invalidActivationCode": "Please enter an activation code"
        }
    },
    "FalaiseComponent": {
        "title": "List of all crags, grouped by region",
        "popover": {
            "route_count": "Number of routes",
            "difficulty": "Min\/max grade"
        },
        "thead": {
            "update": "New",
            "cliff_mobile": "Crag",
            "cliff": "Crag name",
            "route_count_mobile": "Rtes",
            "route_count": "No. of routes",
            "difficulty_mobile": "Grade",
            "difficulty": "Grades"
        }
    },
    "FalaiseList": {
        "popover": {
            "last_update": "Last updated on {{lastUpdateUi}}"
        }
    },
    "GrandeVoieComponent": {
        "title": "List of multi-pitch routes, grouped by region",
        "popover": {
            "length": "Length",
            "difficulty": "Grade",
            "equipmentType": "<strong>Equipment style<\/strong> <br\/> {{description}}"
        },
        "thead": {
            "update": "New",
            "grandeVoie_mobile": "Name",
            "grandeVoie": "Route name",
            "length_mobile": "Lgth",
            "length": "Length",
            "difficulty_mobile": "Gd.",
            "difficulty": "Grade",
            "equipmentType_mobile": "Eq.",
            "equipmentType": "Equipment"
        }
    },
    "GrandeVoieList": {
        "popover": {
            "last_update": "Last updated on {{lastUpdateUi}}"
        }
    },
    "SecteurScreen": {
        "error": {
            "title": "Oops\u2026",
            "description": "You are trying to access a resource that does not exist",
            "back": "Back"
        }
    },
    "MultipitchSwitch": {
        "button": {
            "cta": "{{count}} mulitpich routes"
        }
    },
    "SecteurListComponent": {
        "tab": {
            "info": "Infos",
            "approach": "Approach"
        },
        "properties": {
            "label": {
                "sector_count": "No. of sectors:",
                "approach_time": "Approach time:",
                "rock_type": "Rock:",
                "equiped_by": "Bolters:",
                "warning": "Notifications:"
            }
        },
        "thead": {
            "last_update": "New",
            "sector": "Sector(s)",
            "route_count_mobile": "No.",
            "route_count": "No. of routes",
            "orientation_mobile": "Asp.",
            "orientation": "Aspect",
            "difficulty_mobile": "Diff.",
            "difficulty": "Average diff.",
            "saved": "Saved"
        },
        "popover": {
            "matchQuery": "Contains a multipitch route that matches your query `{{q}}`",
            "remove_in_cart_guidebook": "Withdraw guidebook (book) from cart to remove this item.",
            "route_count": "Number of routes",
            "orientation": "Aspect of the sector",
            "difficulty": "Difficulty of the sector: E=easy: 5 \/ M=medium: 6a \/ H=hard: 6c \/ VH= very hard: 7b \/ EH=extremely hard: 8a",
            "last_update": "Last updated on {{lastUpdateUi}}",
            "saved": "Sector saved",
            "language_availables": "Only available in {{languages}}"
        },
        "approach": {
            "title": "Approach",
            "button": {
                "gmaps_link": "Road access"
            }
        }
    },
    "SecteurComponent": {
        "tab": {
            "sector": "Sector",
            "route_list": "List of routes"
        },
        "properties": {
            "orientation": "Aspect: ",
            "route_count": "Number of routes:",
            "rope_length": "Rope length needed:",
            "climbing_style": "Style: ",
            "approach_time": "Approach time:",
            "family": "Suitable for families:",
            "equipment": "Bolters:",
            "times": "Timing : ",
            "equipmentType": "Equipment : ",
            "firstAscent": "1st ascent : ",
            "requiredMaterial": "Gear : ",
            "descentType": "Descent (type) : ",
            "description": "Introduction"
        },
        "bookmark": {
            "update_bookmark": {
                "title": "Bookmark this page",
                "text": "When you bookmark this page, you erase the previous bookmark",
                "confirmButton": "Bookmark",
                "cancelButton": "No thanks"
            }
        },
        "barchart": {
            "title": "Route distribution"
        },
        "piechart": {
            "title": "Suitable seasons"
        },
        "popover": {
            "warning": "Notifications:",
            "equiped_by": "Initials of route bolter"
        },
        "button": {
            "save": "Save",
            "saved": "Saved",
            "voies": "Guide",
            "report": "Report it!",
            "report_help": "Report back with any important information (approach, bolting, guidebook errors, danger, \u2026)",
            "route": "Guide",
            "approach": "Approach",
            "gmaps_link": "Road access",
            "ticking": "My notes"
        },
        "report": {
            "subject": "Notification about "
        },
        "addToCart": {
            "falaise": "Buy the crag",
            "grande-voie": "Buy the multi-pitch route"
        },
        "removeFromCart": "Remove from the basket",
        "unlock": {
            "falaise": "Buy the crag",
            "grande-voie": "Buy the multi-pitch route"
        }
    },
    "DetailsScreen": {
        "route_list": {
            "title": "Routes list"
        },
        "movement": {
            "title": "Route description"
        },
        "descentDetail": {
            "title": "Descent"
        }
    },
    "DetailsApprocheScreen": {
        "properties": {
            "approach": "Approach",
            "descent": "Descent",
            "description": "Comment"
        }
    },
    "ApproachComponent": {
        "title": {
            "approach": "Approach"
        }
    },
    "UnauthorizedComponent": {
        "title": "Activation required",
        "description": "You don't have access to the <strong>{{label}}<\/strong> guide. Please complete the form to activate your guidebook.",
        "link": "Click HERE to reactivate your account",
        "backlink": "Back to homepage"
    },
    "LoginComponent": {
        "title": "Log in",
        "subtitle": "Enter your information to access your account",
        "btn": {
            "connect": "Log in"
        },
        "link": {
            "register": "Create an account",
            "reset-password": "Forgotten your password?"
        },
        "placeholder": {
            "email": "Email address",
            "password": "Password"
        },
        "error": "An error occured while fetching your data. Please try again or contact us if the problem continue."
    },
    "FullscreenSpinnerComponent": {
        "waitingBtn": {
            "title": "Hide loading screen..."
        }
    },
    "RegisterScreen": {
        "title": "Create an account",
        "subtitle": "Enter your information to create an account",
        "btn": {
            "register": "Create an account"
        },
        "before": {
            "login": "Already registered ?"
        },
        "link": {
            "login": "Log in"
        },
        "placeholder": {
            "email": "Email address",
            "password": "Password",
            "firstName": "First name",
            "lastName": "Last name",
            "passwordConfirm": "Enter your password again to confirm it"
        },
        "form": {
            "firstName": {
                "required": "Enter your first name"
            },
            "lastName": {
                "required": "Enter your last name"
            },
            "email": {
                "required": "Enter your email address",
                "valid": "Enter a valid email address"
            },
            "password": {
                "required": "Enter a password",
                "valid": "Please choose a password that is 8 characters long and using at least one lowercase, one uppercase, one digit and one special character"
            },
            "passwordConfirm": {
                "required": "Enter the password again to confirm it",
                "match": "Please enter identical passwords"
            },
            "newsletter": {
                "accept": "Do you agree to receive communications from OmegaRoc?"
            },
            "cgu": {
                "url": "https:\/\/omegaroc.com\/en\/general-terms-and-conditions\/",
                "accept": "Check this box if you agree to the ",
                "title": "General Conditions of Use",
                "error": "Please check this box."
            }
        },
        "swal": {
            "success": {
                "title": "Welcome!",
                "message": "Your account has been created. You will receive an email confirming your new account.",
                "okBtn": "Ok"
            },
            "error": {
                "title": "An error has occurred",
                "okBtn": "Ok"
            }
        }
    },
    "ResetPasswordScreen": {
        "title": "Forgotten your password?",
        "subtitle": "Enter your email address to receive a link that will allow you to update your password.",
        "btn": {
            "reset-password": "Send an email"
        },
        "link": {
            "login": "Log in"
        },
        "placeholder": {
            "email": "Email address"
        },
        "swal": {
            "success": {
                "title": "Success!",
                "message": "You are going to receive an email telling you how to update your password.",
                "okBtn": "Ok"
            }
        }
    },
    "Omegaroc": {
        "collapse": {
            "open": "Read more",
            "close": "Close"
        },
        "popover": {
            "last_update": "Crag information updated since latest publication of the hard copy guidebook, or new crag not included in the guidebook",
            "multipitch_last_update": "Multipitch route information updated since latest publication of the hard copy guidebook or new multipitch route not included in the guidebook"
        },
        "logo": "OmegaRoc"
    },
    "NoMatch": {
        "title": "Oops\u2026",
        "description": "You are attempting to access a resource that does not exist.",
        "back-link": "Go back",
        "back-home": "Back to home page"
    },
    "SearchComponent": {
        "button": {
            "close": "Close",
            "filter": "Filter",
            "search": "Search",
            "submit": "Display results",
            "clearAll": "Delete all"
        },
        "display": {
            "label": "Display:",
            "list": "List",
            "sort": "Sort:",
            "map": "Map",
            "region": "Region",
            "position_asc": "All",
            "createdAt_desc": "Most recent",
            "label_asc": "A - Z",
            "my_guidebooks": "My guidebooks"
        },
        "field": {
            "search": {
                "label": "Search",
                "placeholder": "Min. 3 characters"
            }
        },
        "title": "Filters"
    },
    "FacetComponent": {
        "facet": {
            "difficulties": "Difficulties",
            "orientations": "Aspect",
            "guidebookIds": "Guidebook",
            "regionIds": "Region",
            "types": "Type of climbing",
            "equipmentTypes": "Equipment",
            "family": "Suitable for families"
        },
        "action": {
            "clear": "Delete"
        }
    },
    "SearchScreen": {
        "title": "Search"
    },
    "TickingFormScreen": {
        "title": "My To do list \/ tick list"
    },
    "TickingListComponent": {
        "title": "My To do list \/ tick list",
        "field": {
            "start": {
                "label": "From"
            },
            "end": {
                "label": "To"
            },
            "tickingType": {
                "label": "Type of ascent",
                "help": "All \/ On sight \/ Flash \/ Sent \/ Working on it\u2026"
            },
            "date": {
                "validate": "Confirm",
                "cancel": "Cancel"
            },
            "wishlist": {
                "label": "To Do list"
            }
        },
        "fab": {
            "action": "Add"
        },
        "alert": {
            "searchEmpty": "There are no results for your criteria.",
            "empty": "Add routes to your tick list.",
            "emptyCta": "Add",
            "error": "Impossible"
        },
        "toggleButton": {
            "all": "All",
            "cliff": "Sport crag",
            "multipitch": "Multi-pitch route"
        }
    },
    "TickingFormComponent": {
        "field": {
            "lastTry": {
                "label": "Date",
                "required": "Please enter a date"
            },
            "addType": {
                "label": "Add route to: ",
                "isTodo": "To do list ",
                "isTick": "Tick list"
            },
            "tickingType": {
                "label": "Type of ascent",
                "required": "Please make a choice",
                "option": {
                    "a_vue": "On sight",
                    "flash": "Flash",
                    "travail": "Working on it\u2026",
                    "enchaine": "Sent",
                    "enchaine_moulinette": "Top rope",
                    "wishlist": "Project"
                }
            },
            "cliffRoute": {
                "label": "Name of the route"
            },
            "route": {
                "label": "Name of the route",
                "labelgrande-voie": "Name of the multi-pitch route",
                "labelfalaise": "Name of the crag",
                "help": "Min. 3 characters",
                "notFound": "Route not found"
            },
            "routeType": {
                "label": "Type of route",
                "option": {
                    "cliff": "Sport crag",
                    "multipitch": "Multi-pitch route"
                },
                "help": "Please select a type of route before searching for a route"
            },
            "tryCount": {
                "label": "No. of attempts",
                "required": "Enter a number"
            },
            "score": {
                "label": "Rating",
                "required": "Please make a selection"
            },
            "comment": {
                "label": "Comments",
                "placeholder": "Comments"
            }
        },
        "fab": {
            "action": "Change"
        },
        "dialog": {
            "title": "Delete",
            "description": "Are you sure you want to delete this route from your tick list?",
            "delete": "Delete",
            "cancel": "Cancel"
        },
        "button": {
            "close": "Close",
            "edit": "Edit"
        },
        "submit": {
            "label": "Save",
            "error": "You are offline, unable to access the content.",
            "autocomplete": "Confirm"
        },
        "message": {
            "autocomplete": "Search for the route you want to add to your tick list."
        },
        "list": {
            "sectorRoute": "Sector:"
        },
        "wishlist": "This route will be added to your TO DO list"
    },
    "MyRouteListScreen": {
        "title": "My routes",
        "alert": {
            "guestMessage": "Log in to add routes.",
            "guestCta": "Log in"
        }
    },
    "TopoListComponent": {
        "alert": {
            "emptySearch": "No guidebook match your criterias. Please update your filter to find guidebooks."
        },
        "button": {
            "clearAll": "Reset filters"
        }
    },
    "RouteListComponent": {
        "alert": {
            "title": "My routes",
            "empty": "No routes are linked to your account.",
            "emptyCta": "Find routes",
            "emptySearch": "No routes fit your criteria. Modify the filters to find routes."
        },
        "button": {
            "clearAll": "Reset the filters"
        }
    },
    "TickingListScreen": {
        "title": "To do list \/ Tick list"
    },
    "AlertComponent": {
        "message": {
            "offline": "You are not connected to the Internet. Only saved routes and sites are accessible using the disk symbol (at the top on the right)."
        },
        "button": {
            "reload": "Reload the page",
            "back": "Back"
        },
        "login": {
            "addToCart": {
                "title": "Buy a guidebook",
                "message": "Log in to buy a guidebook."
            }
        }
    },
    "AlertUnlockComponent": {
        "falaise": {
            "buy_product": "To obtain all information, please buy the crag",
            "guidebook": "You can also access all content for this crag by buying or activating the following guidebook:",
            "guidebook_plural": "You can also access all content for this crag by buying or activating the following guidebooks:"
        },
        "grande-voie": {
            "buy_product": "To obtain all the information, buy the multi-pitch route",
            "guidebook": "You can access all content for this multi-pitch route by buying or activating the following guidebook:",
            "guidebook_plural": "You can access all content for this multi-pitch route by buying or activating the following guidebooks:"
        }
    },
    "ErrorHandler": {
        "message": "An error has occurred",
        "not_found": "No content is available using this address",
        "reload": "Refresh",
        "home": "Back to home page"
    },
    "InfiniteListComponent": {
        "loading": {
            "done": "End of list"
        }
    },
    "RegisterConfirmScreen": {
        "swal": {
            "success": {
                "title": "Congratulations!",
                "message": "You have just confirmed your email address! You can now start viewing guidebooks.",
                "okBtn": "Let's go!"
            },
            "error": {
                "title": "Warning!",
                "okBtn": "Ok"
            }
        }
    },
    "ResetPasswordConfirmScreen": {
        "title": "Change your password",
        "subtitle": "Enter your new password",
        "btn": {
            "reset-password": "Save"
        },
        "placeholder": {
            "password": "Password",
            "passwordConfirm": "Enter your new password again"
        },
        "swal": {
            "success": {
                "title": "Success!",
                "message": "Your password has been updated! You are free to go back to your guidebooks.",
                "okBtn": "Ok"
            }
        }
    },
    "searchResults": {
        "avatar": {
            "cliff": "Cr",
            "multipitch": "MP",
            "mixte": "Cr\/MP"
        },
        "routes": "Routes",
        "grades": {
            "cliff": "Min. Grade \/ Max. Grade",
            "multipitch": "Maximum grade \/ Obligatory grade"
        },
        "lengthMax": "max",
        "lengthMaxHelp": {
            "cliff": "Max. height of the routes",
            "multipitch": "Route length"
        },
        "length": "m",
        "difficulty": {
            "help": "Difficulty \/ Equipment<br\/><br\/>Eq1\/Eq2 : Trad climbing<br\/>Eq3\/Eq4 : Bolted"
        }
    },
    "Prix : ": "Price:",
    "AddressUpdateComponent": {
        "title": "Change an address",
        "subtitle": "Enter your information"
    },
    "AddressCreateComponent": {
        "title": "Add an address",
        "subtitle": "Enter your information",
        "placeholder": {
            "firstName": "First name",
            "lastName": "Last name",
            "businessName": "Company name (optional)",
            "address": "Address",
            "zipcode": "Postal code",
            "city": "City",
            "countryCode": "Country",
            "country": "Country",
            "phone": "Phone"
        },
        "help": {
            "phone": "Le numéro de téléphone peut-être utilisé pour la livraison"
        },
        "btn": {
            "save": "Save",
            "back": "Back"
        },
        "form": {
            "address": {
                "required": "Enter your street and street number"
            },
            "zipcode": {
                "required": "Enter your postal code"
            },
            "city": {
                "required": "Enter your city"
            },
            "countryCode": {
                "required": "Select a country"
            },
            "country": {
                "required": "Select a country"
            },
            "phone": {
                "required": "Enter a phone number"
            }
        }
    },
    "UserInformationUpdateComponent": {
        "alert": {
            "success": "Your information has been updated"
        }
    },
    "PasswordUpdateComponent": {
        "title": "Change your password",
        "subtitle": "Enter your new password",
        "placeholder": {
            "currentPassword": "Current password",
            "password": "New password",
            "passwordConfirm": "Enter your password again to confirm it"
        },
        "btn": {
            "save": "Save",
            "back": "Back"
        },
        "alert": {
            "success": "Your password has been updated"
        },
        "form": {
            "currentPassword": {
                "required": "Enter your current password"
            },
            "password": {
                "required": "Enter your new password"
            },
            "passwordConfirm": {
                "required": "Enter your new password again"
            }
        }
    },
    "CartScreen": {
        "item": {
            "isNotVirtual": "Book",
            "subtitle": {
                "guidebook": "Topo papier",
                "guidebook_virtual": "Topo numérique",
                "cliff": "Site - Topo papier",
                "cliff_virtual": "Site - Topo numérique",
                "multipitch": "GV - Topo papier",
                "multipitch_virtual": "GV - Topo numérique"
            }
        }
    },
    "BillingComponent": {
        "btn": {
            "continue": "Continue",
            "back": "Back to basket"
        }
    },
    "common": {
        "add": "Add",
        "back": "Back",
        "cancel": "Cancel",
        "confirm": "Confirm",
        "error": {
            "save": "Error while updating",
            "delete": "Error while deleting",
            "create": "Error while creating",
            "update": "Error while updating",
            "logout": "Error while login out"
        },
        "date_placeholder": "DD/MM/YYYY"
    },
    "Order": {
        "marking": {
            "completed": "Completed",
            "cancelled": "Canceled"
        }
    },
    "seo": {
        "grades": "at grade from {{minGrade}} to {{maxGrade}}",
        "title": {
            "topos": "Climbing guidebook OmegaRoc - All the guidebooks",
            "search": "Climbing guidebook OmegaRoc - Search",
            "myroute": "Climbing guidebook OmegaRoc - My routes",
            "guidebook": "Climbing guidebook {{label}} - OmegaRoc",
            "cliff": "Guidebook of the sport crag {{label}} - {{region}} - OmegaRoc",
            "multi_pitch_route": "Guidebook of the multi-pitch route {{label}} - {{region}} - OmegaRoc",
            "sector_route": "Guidebook of  {{label}}, at the climbing site {{cliff}} - OmegaRoc"
        },
        "description": {
            "search": "All the sport crags and multi-pitch routes available on the OmegaRoc app",
            "topos": "All the climbing guidebooks available on the OmegaRoc app",
            "myroute": "All my routes in the OmegaRoc app",
            "guidebook": "Description {{label}} {{description}} - OmegaRoc",
            "cliff": "Detailed information and digital guidebook of the climbing site  {{label}} which has {{routeCount}} routes {{grades}}",
            "multi_pitch_route": "Digital guidebook of the multi-pitch route {{label}}, {{length}} height, at grade {{difficulty}} {{maxGrade}}",
            "sector_route": "Detailed information and digital guidebook about the {{label}} sector, at the climbing site {{cliff}} which has {{routeCount}} routes {{grades}}"
        },
        "grade": "at grade {{grade}}"
    },
    "UpdatePWAComponent": {
        "updateText": "Update available",
        "cta" : "Install",
        "close": "Close"
    }
}

export default en;