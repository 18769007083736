import React, {useEffect} from 'react';
import { withTranslation} from 'react-i18next';
import {Alert, Box, Typography} from '@mui/material';
import {compose} from "redux";
import {connect} from "react-redux";
import {useFlashMessageAck} from "../../../hooks/useFlashMessages";
import {toast} from "react-toastify";
import {MuiButton} from "../../MuiButton";

const AlertFlashMessage = ({flashMessage, i18n, t}) => {
    const [open, setOpen] = React.useState(true);
    const { data, mutateAsync, status, error } = useFlashMessageAck(i18n.language);
    const handleClose = (flashMessageId) => {
        try {
            setOpen(false);
            mutateAsync(flashMessageId).then(r => {

            });

        } catch (e) {
            toast.error(t('common.error.save'));
            setOpen(true);
        }
    };

    useEffect(() => {
        setOpen(true);
    }, [flashMessage]);


    return <>
            {flashMessage.severity && <Alert
                severity={flashMessage.severity}
                style={{marginTop: "15px", marginBottom: "15px"}}>
                <Box display="flex" alignItems="flex-start">
                    <Box flexGrow={1}>
                        <Typography variant="h3" gutterBottom dangerouslySetInnerHTML={{__html: flashMessage.label}}/>
                    </Box>
                    {/*{flashMessage.discardable && open && <Box>*/}
                    {/*    <IconButton*/}
                    {/*        aria-label="close flash message"*/}
                    {/*        onClick={() => handleClose(flashMessage.id)}*/}
                    {/*        sx={{pt:0}}*/}
                    {/*    >*/}
                    {/*        <FontAwesomeIcon icon={['fas','times']}/>*/}
                    {/*    </IconButton>*/}
                    {/*</Box>}*/}
                </Box>


                <p className="wysiwyg" dangerouslySetInnerHTML={{__html: flashMessage.content}}/>
                {/*<Typography variant="body1" gutterBottom dangerouslySetInnerHTML={{__html:flashMessage.content}}/>*/}
            </Alert>}
        {!flashMessage.severity && <>
            <Typography variant="h3" gutterBottom dangerouslySetInnerHTML={{__html: flashMessage.label}}/>
            <p className="wysiwyg" dangerouslySetInnerHTML={{__html: flashMessage.content}}/>

            <Box display="flex" alignItems="flex-start">
                <Box flexGrow={1}>
                </Box>
                {flashMessage.discardable && open && <Box>
                    <MuiButton onClick={() => handleClose(flashMessage.id)}
                               variant="contained"
                               color="primary">
                        {t("FlashMessage.confirm")}
                    </MuiButton>
                </Box>}
            </Box>
            {/*<Typography variant="body1" gutterBottom dangerouslySetInnerHTML={{__html:flashMessage.content}}/>*/}
        </>}
    </>;
};

const mapStateToProps = (state) => {
    return {}
};

export default compose(
    withTranslation(),
    connect(
        mapStateToProps
    ))(AlertFlashMessage);
