const Constants = {
    BRAND: "Omegaroc",
    TypeTopo: {
        FALAISE: "falaise",
        FALAISE_FR: "falaise",
        FALAISE_EN: "crag",
        GRANDEVOIE: "grande-voie",
        GRANDEVOIE_FR: "grande-voie",
        GRANDEVOIE_EN: "multi-pitch",
        color: {
            FALAISE: "#6588A6",
            GRANDEVOIE: "#a66556",
            MIXTE: "#b6a545",
        }
    },
    headerHeight: 56,
    headerHeightMobile: 56,
    headerHeightDesktop: 64,
    footerHeight: 36,
    tickingTypeDefaultValue: 10,
    tickingTypeValueWishlist: 50,
    tickingTypeOptionsValue: null,
    // tickingTypeOptions: [
    //     {value: 10, label: "TickingFormComponent.field.tickingType.option.a_vue", icon: "eye", color: "primary"},
    //     {value: 20, label: "TickingFormComponent.field.tickingType.option.flash", icon: "bolt-lightning", color: "primary"},
    //     {value: 30, label: "TickingFormComponent.field.tickingType.option.enchaine", icon: "circle-check", color: "primary"},
    //     {value: 35, label: "TickingFormComponent.field.tickingType.option.enchaine_moulinette", icon: "u", color: "primary"},
    //     {value: 40, label: "TickingFormComponent.field.tickingType.option.travail", icon: "cogs", color: "primary"},
    //     //{value: 50, label: "TickingFormComponent.field.tickingType.option.wishlist", icon: "heart", color: "primary"},
    // ],
    allTickingTypeOptions: [
        {value: 10, label: "TickingFormComponent.field.tickingType.option.a_vue", icon: ["fa-regular","eye"], color: "primary"},
        {value: 20, label: "TickingFormComponent.field.tickingType.option.flash", icon: ["fa","bolt-lightning"], color: "primary"},
        {value: 30, label: "TickingFormComponent.field.tickingType.option.enchaine", icon: ["fa-regular","circle-check"], color: "primary"},
        {value: 35, label: "TickingFormComponent.field.tickingType.option.enchaine_moulinette", icon: "u", color: "primary fa-rotate-180"},
        {value: 40, label: "TickingFormComponent.field.tickingType.option.travail", icon: ["fa", "cogs"], color: "primary"},
        {value: 50, label: "TickingFormComponent.field.tickingType.option.wishlist", icon: ["fa", "heart"], color: "primary"},
    ],
    listAction: {
        addToCart: 'add_to_cart',
        myRoute: 'my_route',
        inCart: 'in_cart',
        inCartGuidebook: 'in_cart_guidebook',
        saved: 'saved',
        save: 'save',
    },
    cart: {
        shippingMethods: {
            standard: 'standard',
            express: 'express'
        }
    },
    listComponentActions: [
        {code: 'add_to_cart', label: '', icon: ['fas', 'cart-plus'], color: 'primary', function: 'addToCart'},
        {code: 'my_route', label: '', icon: ['fas', 'star'], color: 'primary'},
        {code: 'in_cart', label: '', icon: ['fas', 'shopping-cart'], color: 'secondary', function: 'removeFromCart'},
        {code: 'in_cart_guidebook', label: '', icon: ['fas', 'shopping-cart'], color: 'secondary', function: 'disabledRemoveFromCart'},
        {code: 'saved', label: '', icon: ['fas', 'save'], color: 'secondary', function: 'unsave'},
        {code: 'save', label: '', icon: ['fas', 'save'], color: 'primary', function: 'save'},
        {code: 'tickingType-10', label: '', icon: ['fa-regular', 'eye'], color: 'primary'},
        {code: 'tickingType-20', label: '', icon: ['fas', 'bolt-lightning'], color: 'primary'},
        {code: 'tickingType-30', label: '', icon: ['fa-regular', 'circle-check'], color: 'primary'},
        {code: 'tickingType-35', label: '', icon: ['fas', 'u'], color: 'primary fa-rotate-180'},
        {code: 'tickingType-40', label: '', icon: ['fas', 'cogs'], color: 'primary'},
    ],
    SCREEN_NAME: {
        SEARCH: 'Search',
        MY_ROUTE: 'MyRoute',
        TICKING_LIST: 'TickingList',
        TOPO: 'Topo',
    },
    search: {
        guidebook: {
            limit: 50,
        },
    },
    CLIFF: 'cliff',
    MULTIPITCH: 'multipitch',
    MIXTE: 'mixte',
    PHYSICAL_PRODUCT: 'physical',
    VIRTUAL_PRODUCT: 'virtual',
    VIEWPORT: {
        LATITUDE: 43.915792,
        LONGITUDE: 5.213068,
        ZOOM: 4,
        ZOOM_GEO_LOCATE: 11,
    },
    ROUTER: {
        PRESENTATION: 'presentation',
        ROUTE: 'route',
        SEARCH: 'search',
        TOPO: 'topo',
        TOPOS: 'topos',
    },
    localStorageKey: {
        searchListItemResult: 'OME_searchListItemResult'
    },
    SW_VERSION: 20250214102141,
    RouteListComponent: {
        queryOrder: {
            list: 'list',
            region: 'region',
            map: 'map'
        }
    }
}

export default Constants;