const fr ={
    "App": {
        "components": {
            "PresentationComponent": {
                "button": "Info"
            },
            "AddressListComponent": {
                "title": "Mes adresses",
                "message": {
                    "confirmDelete": "\u00cates-vous s\u00fbr de vouloir supprimer cette adresse ?",
                    "confirmDefault": "\u00cates-vous s\u00fbr de vouloir d\u00e9finir cette adresse par d\u00e9faut ?",
                    "defaultAddress": "Adresse par d\u00e9faut",
                    "defaultButtonText": "Par d\u00e9faut",
                    "deleteAddressButtonText": "Supprimer",
                    "addAddressButtonText": "Ajouter"
                },
                "button": {
                    "update": "Modifier"
                }
            },
            "BillingComponent": {
                "h1Title": "Informations de facturation",
                "message": {
                    "billingAddress": "Adresse de facturation",
                    "shippingAddress": "Adresse de livraison",
                    "differentShippingAddress": "Adresse de livraison diff\u00e9rente ?"
                },
                "button": {
                    "finalizeCommandButton": "Finaliser ma commande"
                }
            },
            "CompleteComponent": {
                "success": {
                    "title": "Votre commande est un succ\u00e8s",
                    "message": "Votre topo est disponible dans \"Mes voies\"",
                    "ctaLabel": "Mes voies"
                },
                "cancelled": {
                    "title": "Votre commande n'a pas pu aboutir",
                    "message": "Votre commande n'a pas pu aboutir",
                    "ctaLabel": "Mon panier"
                },
                "h1Title": {
                    "success": "Votre commande est un succ\u00e8s",
                    "cancelled": "Votre commande n'a pas pu aboutir"
                }
            },
            "PaymentComponent": {
                "h1Title": "R\u00e9capitulatif et paiement",
                "message": {
                    "totalWithoutReduction": "Total avant remise",
                    "reductionAmount": "R\u00e9duction {reductionAmount}% TTC",
                    "shippingCostVat": "Frais de port",
                    "shippingCostVatHelp": "Livraison en France : 3 à 4 jours ouvrés <b>avec suivi<\/b> (5 €)<br\/>Livraison à l'étranger : en 2 à 4 semaines <b>sans suivi<\/b> (5 €)<br\/>Pour une livraison express à l'étranger : avec suivi, délai 1 semaine (15€) - cocher la case ci-dessous",
                    "shippingMethodExpress": "Colis suivi express à l'étranger (+10€)",
                    "totalVat": "Total TTC"
                },
                "button": {
                    "finalizeCommandButton": "Finaliser ma commande",
                    "payWithCB": "Payer par Carte Bancaire"
                }
            },
            "OrderListComponent": {
                "h1Title": "Mes commandes",
                "message": {
                    "seeMoreButtonText": "Voir",
                    "noOrder": "Vous n'avez pas encore effectu\u00e9 de commande"
                }
            },
            "OrderDetailsComponent": {
                "h1Title": "D\u00e9tails de ma commande",
                "orderNumber": "Commande #{{number}}"
            },
            "PasswordUpdateComponent": {
                "message": {
                    "changePassword": "Modifier mon mot de passe"
                }
            },
            "UserInformationUpdateComponent": {
                "h1Title": "Modifier mes informations",
                "myAccount": "Mon identifiant",
                "message": {
                    "typeInformations": "Saisissez votre nom et pr\u00e9nom"
                }
            }
        },
        "common": {
            "yes": "Oui",
            "no": "Non",
            "promptInstall1": "Pour installer cette WebApp sur votre iPhone\/iPad cliquez sur ",
            "promptInstall2": " puis Ajouter \u00e0 l\\'\u00e9cran d\\'accueil.",
            "en": "EN",
            "fr": "FR",
            "language": {
                "fr": "Fran\u00e7ais",
                "en": "Anglais"
            }
        },
        "screens": {
            "CartScreen": {
                "h1Title": {
                    "empty": "Votre panier est vide.",
                    "yourCart": "Votre panier"
                },
                "message": {
                    "totalVat": "Sous-total",
                    "shippingCostVat": "Frais de port",
                    "shippingCostVatHelp": "Livraison en France : 3 à 4 jours ouvrés <b>avec suivi<\/b> (5 €)<br\/>Livraison à l'étranger : en 2 à 4 semaines <b>sans suivi<\/b> (5 €)<br\/>Pour une livraison express à l'étranger : avec suivi, délai 1 semaine (15€) - cocher la case ci-dessous",
                    "validationButton": "Valider mon panier",
                    "promotionTable": {
                        "title": "Table des r\u00e9ductions",
                        "subtitle": "Les r\u00e9ductions s'appliquent uniquement sur les \u00e9l\u00e9ments num\u00e9riques (hors Topo papier)",
                        "table": {
                            "minAmount": "Montant minimum",
                            "reductionPercent": "Pourcentage de r\u00e9duction"
                        }
                    }
                },
                "addedToCart": " ajout\u00e9 au panier !",
                "removedFromCart": " retir\u00e9 du panier !",
                "title": "Votre panier"
            },
            "TopoList": {
                "title": "Choix du topo",
                "no-topo": "Aucun topo disponible pour le moment...",
                "no-topo-desc": "Erreur de connexion, v\u00e9rifiez que vous \u00eates connect\u00e9 \u00e0 internet."
            },
            "LoginScreen": {
                "title": "Connexion"
            },
            "UserScreen": {
                "title": "Mon Compte"
            },
            "OrderScreen": {
                "title": "Mes Commandes"
            },
            "UserInformationUpdateScreen": {
                "h1Title": "Mon profil"
            }
        },
        "footer": {
            "label": "\u00a9 OmegaRoc 2020 - Tous droits r\u00e9serv\u00e9s"
        },
        "menu": {
            "myroute": "Mes voies",
            "search": "Recherche",
            "guidebook": "Topos",
            "cart": "Panier",
            "ticking": "Mon carnet",
            "account": "Mon profil",
            "adresses": "Mes adresses",
            "orders": "Mes commandes",
            "logout": "Se d\u00e9connecter"
        }
    },
    "Navbar": {
        "account": "Mon compte",
        "back": "Retour",
        "close": "Fermer",
        "guidebooks": "Topos",
        "cart": "Mon panier",
        "logout": "Se d\u00e9connecter",
        "menu": "Menu",
        "ticking": "Carnet de croix",
        "myroutes": "Mes voies",
        "search": "Rechercher",
        "searchLabel": "Rechercher",
        "Facebook": "Facebook",
        "Omegaroc": "OmegaRoc",
        "FondEquipement": "Fond d'\u00e9quipement",
        "cgv": "Conditions g\u00e9n\u00e9rales",
        "Aboutus": "\u00c0 propos",
        "EthicalGuidebook": "Des topos \u00e9thiques",
        "userBook": "Mode d'emploi",
        "saveRoute": {
            "guest": "Veuillez vous connecter pour enregistrer des voies",
            "label": "Mes voies enregistr\u00e9es",
            "cliff": "Falaises",
            "multipitch": "Grandes voies",
            "clearAll": "Tout supprimer",
            "empty": "Vous n'avez enregistr\u00e9 aucune voie.",
            "bookmarkEmpty": "Aucune voie marqu\u00e9e",
            "delete": {
                "confirm": "Confirmer la suppression",
                "confirmAll": "Confirmer la suppression de TOUTES les voies enregistr\u00e9es"
            }
        }
    },
    "FlashMessage": {
        "confirm": "J'ai compris"
    },
    "NoMatch": {
        "title": "Oups...",
        "description": "Vous essayez d'acc\u00e9der \u00e0 une ressource qui n'existe pas.",
        "back-link": "Revenir en arri\u00e8re",
        "back-home": "Retourner \u00e0 l'accueil"
    },
    "TopoScreen": {
        "unlock": {
            "price": "Ce topo est disponible \u00e0 l'achat.",
            "isQuestionable": "Si vous poss\u00e9dez d\u00e9j\u00e0 le topo papier, vous pouvez l'activer gratuitement en r\u00e9pondant \u00e0 une question.",
            "code": "Vous pouvez l'activer en r\u00e9pondant \u00e0 une question.",
            "endValidationTime": "L'activation arrive \u00e0 expiration dans les prochains jours. Vous pouvez r\u00e9-activer le topo d\u00e8s \u00e0 pr\u00e9sent.",
            "cta": "R\u00e9-activer votre topo"
        },
        "description": "Retrouvez l'int\u00e9gralit\u00e9 du contenu du topo dans la liste d\u00e9ployable ci-dessous."
    },
    "TopoAddToCart": {
        "buyDigitalVersion": "Acheter la version num\u00e9rique",
        "accessGuidebook": "Acc\u00e9der au topo",
        "unlockGuidebook": "D\u00e9bloquer",
        "unlockDigitalGuidebook": "Activer la version num\u00e9rique",
        "digitalVersionOnly": "Topo disponible uniquement en version d\u00e9mat\u00e9rialis\u00e9",
        "buyGuidebook": "Acheter le livre et la version num\u00e9rique"
    },
    "TopoValidationScreen": {
        "description": "Pour avoir acc\u00e8s au contenu num\u00e9ris\u00e9 du topo {{label}} vous devez poss\u00e9der le livre et vous enregistrer.<br\/>Pour cela il faut renseigner votre adresse e-mail et r\u00e9pondre \u00e0 la question ci-dessous<br\/>",
        "popin": {
            "title": {
                "success": "R\u00e9ponse correcte",
                "failed": "R\u00e9ponse incorrecte"
            },
            "description": {
                "success": "Vous pouvez acc\u00e9der \u00e0 tout le contenu du topo dans \u00ab Mes voies \u00bb (dans le bandeau en bas d\u2019\u00e9cran)"
            },
            "error": {
                "title": "R\u00e9ponse incorrecte",
                "message": "Le code d'activation n'est pas valide"
            },
            "button": {
                "ok": "Ok",
                "typeCode": "Acc\u00e9der au topo"
            }
        },
        "title": "Activation d'un topo",
        "alert": {
            "guestMessage": "Connectez vous pour d\u00e9bloquer un topo.",
            "guestCta": "Me connecter",
            "missingQuestion": "Pas de questions disponibles pour ce topo."
        },
        "form": {
            "input": {
                "email": {
                    "label": "Entrez votre adresse e-mail",
                    "placeholder": "Email"
                },
                "edition": {
                    "label": "Edition du guide :"
                },
                "reponse": {
                    "placeholder": "R\u00e9ponse"
                },
                "submit": {
                    "text": "Valider"
                }
            },
            "help": "Un message d'activation vous sera envoy\u00e9 \u00e0 l'adresse renseign\u00e9e."
        }
    },
    "ActivationScreen": {
        "title": {
            "default": "Activation d'un topo",
            "error": "Une erreur est survenue"
        },
        "link": {
            "label": "Pour reg\u00e9nerer un email d'activation,",
            "cta": "cliquer ici",
            "activationLabel": "Pour resaisir le code d'activation,",
            "activationCta": "cliquer ici"
        },
        "error": {
            "invalidActivationCode": "Veuillez saisir un code d'activation"
        },
        "form": {
            "title": "Veuillez entrer un code d'activation :",
            "code": {
                "label": "Code d'activation"
            },
            "submit": "Valider"
        }
    },
    "FalaiseComponent": {
        "title": "Liste des sites class\u00e9s par r\u00e9gions",
        "popover": {
            "route_count": "Nombre de voies",
            "difficulty": "Niveau mini \/ max"
        },
        "thead": {
            "update": "Nouveau",
            "cliff_mobile": "Site",
            "cliff": "Nom du site",
            "route_count_mobile": "Nb",
            "route_count": "Nb voies",
            "difficulty_mobile": "Niveau",
            "difficulty": "Niveau"
        }
    },
    "FalaiseList": {
        "popover": {
            "last_update": "Mis \u00e0 jour le {{lastUpdateUi}}"
        }
    },
    "GrandeVoieComponent": {
        "title": "Liste des grandes voies class\u00e9es par r\u00e9gions",
        "popover": {
            "length": "Longueur",
            "difficulty": "Niveau",
            "equipmentType": "<strong>Type \u00e9quipement<\/strong> <br\/> {{description}}"
        },
        "thead": {
            "update": "Nouveau",
            "grandeVoie_mobile": "Nom",
            "grandeVoie": "Nom de la voie",
            "length_mobile": "Long",
            "length": "Longueur",
            "difficulty_mobile": "Niv.",
            "difficulty": "Niveau",
            "equipmentType_mobile": "Eq.",
            "equipmentType": "Equipement"
        }
    },
    "GrandeVoieList": {
        "popover": {
            "last_update": "Mis \u00e0 jour le {{lastUpdateUi}}"
        }
    },
    "SecteurScreen": {
        "error": {
            "title": "Oups...",
            "description": "Vous essayez d'acc\u00e9der \u00e0 une ressource qui n'existe pas",
            "back": "Retour \u00e0 la page pr\u00e9c\u00e9dente"
        }
    },
    "MultipitchSwitch": {
        "button": {
            "cta": "{{count}} grandes voies"
        }
    },
    "SecteurListComponent": {
        "tab": {
            "info": "Infos",
            "approach": "Approche"
        },
        "properties": {
            "label": {
                "sector_count": "Nb secteurs :",
                "approach_time": "Temps d'approche : ",
                "rock_type": "Rocher : ",
                "equiped_by": "Equipeurs : ",
                "warning": "Attention : "
            }
        },
        "thead": {
            "last_update": "Nouveau",
            "sector": "Secteur(s)",
            "route_count_mobile": "Nb",
            "route_count": "Nb voies",
            "orientation_mobile": "Exp.",
            "orientation": "Exposition",
            "difficulty_mobile": "Diff.",
            "difficulty": "Difficult\u00e9",
            "saved": "Enregistr\u00e9"
        },
        "popover": {
            "matchQuery": "Contient une grande voie qui correspond à votre recherche `{{q}}`",
            "remove_in_cart_guidebook": "Retirer le topo (livre) pour retirer cette voie",
            "route_count": "Nombre de voies",
            "orientation": "Orientation du secteur",
            "difficulty": "Difficult\u00e9 moyenne du secteur : F : 5 \/ M : 6a \/ D : 6c \/ TD : 7b \/ ED : 8a",
            "last_update": "Mis \u00e0 jour le {{lastUpdateUi}}",
            "saved": "Secteur enregistr\u00e9",
            "language_availables": "Uniquement disponible en {{languages}}"
        },
        "approach": {
            "title": "Approche \/ description",
            "button": {
                "gmaps_link": "Acc\u00e8s routier"
            }
        }
    },
    "SecteurComponent": {
        "tab": {
            "sector": "Secteur",
            "route_list": "Liste des voies"
        },
        "addToCart": {
            "falaise": "Acheter la falaise",
            "grande-voie": "Acheter la grande voie"
        },
        "removeFromCart": "Retirer du panier",
        "unlock": {
            "falaise": "Acheter la falaise",
            "grande-voie": "Acheter la grande voie"
        },
        "properties": {
            "orientation": "Orientation : ",
            "route_count": "Nombre de voies : ",
            "rope_length": "Corde n\u00e9cessaire : ",
            "climbing_style": "Style : ",
            "approach_time": "Temps d'approche : ",
            "family": "Adapt\u00e9 aux familles : ",
            "equipment": "Equipeurs : ",
            "times": "Horaires : ",
            "equipmentType": "Equipement : ",
            "firstAscent": "1e ascension : ",
            "requiredMaterial": "Mat\u00e9riel : ",
            "descentType": "Descente (type) : ",
            "description": "Pr\u00e9sentation"
        },
        "bookmark": {
            "update_bookmark": {
                "title": "Ajouter le marque page",
                "text": "En marquant cette page, vous effacez la marque pr\u00e9c\u00e9dente.",
                "confirmButton": "Marquer",
                "cancelButton": "Non, merci"
            }
        },
        "barchart": {
            "title": "R\u00e9partition des voies"
        },
        "piechart": {
            "title": "P\u00e9riode propice"
        },
        "popover": {
            "warning": "Attention : ",
            "equiped_by": "Initiales de l'\u00e9quipeur de la voie"
        },
        "button": {
            "save": "Enregistrer",
            "saved": "Enregistr\u00e9",
            "voies": "Topo",
            "report": "Signaler",
            "report_help": "Pour signaler toute remarque importante (approche, \u00e9quipement, erreur, danger, ...)",
            "route": "Topo",
            "approach": "Approche",
            "gmaps_link": "Acc\u00e8s routier",
            "ticking": "Mes notes"
        },
        "report": {
            "subject": "Remarque sur "
        }
    },
    "DetailsScreen": {
        "route_list": {
            "title": "Liste des voies"
        },
        "movement": {
            "title": "Cheminement"
        },
        "descentDetail": {
            "title": "Descente"
        }
    },
    "DetailsApprocheScreen": {
        "properties": {
            "approach": "Approche",
            "descent": "Descente",
            "description": "Commentaire"
        }
    },
    "ApproachComponent": {
        "title": {
            "approach": "Approche"
        }
    },
    "UnauthorizedComponent": {
        "title": "Activation requise",
        "description": "Vous n'avez pas acc\u00e8s au topo <strong>{{label}}<\/strong>. Proc\u00e9der \u00e0 l'activation en remplissant le formulaire.",
        "link": "Cliquer ICI pour r\u00e9activer votre compte",
        "backlink": "Retourner \u00e0 l'accueil"
    },
    "SearchComponent": {
        "button": {
            "close": "Fermer",
            "filter": "Filtrer",
            "search": "Rechercher",
            "submit": "Voir les r\u00e9sultats",
            "clearAll": "Tout effacer"
        },
        "display": {
            "label": "Affichage :",
            "sort": "Trier :",
            "list": "Liste",
            "map": "Carte",
            "region": "R\u00e9gion",
            "position_asc": "Tous les topos",
            "createdAt_desc": "Plus r\u00e9cent",
            "label_asc": "A - Z",
            "my_guidebooks": "Mes topos"
        },
        "field": {
            "search": {
                "label": "Recherche",
                "placeholder": "3 caract\u00e8res min."
            }
        },
        "title": "Filtres"
    },
    "FacetComponent": {
        "facet": {
            "difficulties": "Difficult\u00e9",
            "orientations": "Orientation",
            "guidebookIds": "Topo",
            "regionIds": "R\u00e9gion",
            "types": "Type d'escalade",
            "equipmentTypes": "Type d'\u00e9quipement",
            "family": "Adapt\u00e9 famille"
        },
        "action": {
            "clear": "Effacer"
        }
    },
    "SearchScreen": {
        "title": "Recherche"
    },
    "TickingFormScreen": {
        "title": "Mon carnet"
    },
    "TickingListComponent": {
        "title": "Mon carnet",
        "field": {
            "start": {
                "label": "Date D\u00e9but"
            },
            "end": {
                "label": "Date Fin"
            },
            "tickingType": {
                "label": "Encha\u00eenement",
                "help": "Tous \/ A vue \/ Flash \/ Encha\u00een\u00e9e \/ En travail"
            },
            "wishlist": {
                "label": "Projet"
            },
            "date": {
                "validate": "Valider",
                "cancel": "Annuler"
            }
        },
        "fab": {
            "action": "Ajouter"
        },
        "alert": {
            "searchEmpty": "Aucun r\u00e9sultat ne correspond \u00e0 vos crit\u00e8res.",
            "empty": "Ajoutez des voies dans votre carnet.",
            "emptyCta": "Ajouter",
            "error": "Impossible"
        },
        "toggleButton": {
            "all": "Tous",
            "cliff": "Falaise",
            "multipitch": "Grande Voie"
        }
    },
    "TickingFormComponent": {
        "field": {
            "lastTry": {
                "label": "Date",
                "required": "Merci de saisir une date"
            },
            "addType": {
                "label": "Ajouter la voie : ",
                "isTodo": "Projet",
                "isTick": "Croix"
            },
            "tickingType": {
                "label": "Encha\u00eenement",
                "required": "Merci de faire un choix",
                "option": {
                    "a_vue": "A vue",
                    "flash": "Flash",
                    "travail": "En travail",
                    "enchaine": "Encha\u00een\u00e9e",
                    "enchaine_moulinette": "Enchaînée en moulinette",
                    "wishlist": "Projet"
                }
            },
            "cliffRoute": {
                "label": "Nom de la voie"
            },
            "route": {
                "label": "Nom de la voie",
                "labelgrande-voie": "Nom de la grande voie",
                "labelfalaise": "Nom de la falaise",
                "help": "3 caract\u00e8res min.",
                "notFound": "Voie non trouv\u00e9e"
            },
            "routeType": {
                "label": "Type de voie",
                "option": {
                    "cliff": "Falaise",
                    "multipitch": "Grande voie"
                },
                "help": "Choisissez un type de voie avant de rechercher une voie"
            },
            "tryCount": {
                "label": "Nbre tentatives",
                "required": "Merci de saisir un nombre"
            },
            "score": {
                "label": "Int\u00e9r\u00eats",
                "required": "Merci de faire un choix"
            },
            "comment": {
                "label": "Commentaire",
                "placeholder": "Commentaire"
            }
        },
        "fab": {
            "action": "Modifier"
        },
        "dialog": {
            "title": "Supprimer",
            "description": "Etes-vous s\u00fbr de vouloir supprimer cette voie de votre carnet ?",
            "delete": "Supprimer",
            "cancel": "Annuler"
        },
        "button": {
            "close": "Fermer",
            "edit": "Editer"
        },
        "submit": {
            "label": "Enregistrer",
            "error": "Impossible, vous n\u2019avez pas de r\u00e9seau",
            "autocomplete": "Valider"
        },
        "message": {
            "autocomplete": "Rechercher la voie \u00e0 ajouter \u00e0 votre carnet de croix."
        },
        "list": {
            "sectorRoute": "Secteur :"
        },
        "wishlist": "Cette voie sera ajout\u00e9e \u00e0 votre liste de projets"
    },
    "MyRouteListScreen": {
        "title": "Mes voies",
        "alert": {
            "guestMessage": "Connectez vous pour ajouter des voies.",
            "guestCta": "Me connecter"
        }
    },
    "TopoListComponent": {
        "alert": {
            "emptySearch": "Aucun topo ne r\u00e9pond \u00e0 vos crit\u00e8res. Modifier les filtres pour trouver des topos."
        },
        "button": {
            "clearAll": "R\u00e9-initialiser les filtres"
        }
    },
    "RouteListComponent": {
        "alert": {
            "title": "Mes voies",
            "empty": "Aucune voie n'est li\u00e9e \u00e0 votre compte.",
            "emptyCta": "Chercher des voies",
            "emptySearch": "Aucune voie ne r\u00e9pond \u00e0 vos crit\u00e8res. Modifier les filtres pour trouver des voies."
        },
        "button": {
            "clearAll": "R\u00e9-initialiser les filtres"
        }
    },
    "TickingListScreen": {
        "title": "Carnet"
    },
    "AlertComponent": {
        "message": {
            "offline": "Vous n'\u00eates pas connect\u00e9 \u00e0 Internet. Seuls les voies et sites enregistr\u00e9s sont accessibles dans le symbole Disquette (en haut \u00e0 droite)."
        },
        "button": {
            "reload": "Recharger la page",
            "back": "Retour"
        },
        "login": {
            "addToCart": {
                "title": "Achat d'un topo",
                "message": "Connectez vous pour acheter un topo."
            }
        }
    },
    "AlertUnlockComponent": {
        "falaise": {
            "buy_product": "Pour avoir toutes les informations, achetez la falaise",
            "guidebook": "Vous pouvez aussi acc\u00e9der au contenu complet de cette falaise en achetant ou d\u00e9bloquant le topo suivant :",
            "guidebook_plural": "Vous pouvez aussi acc\u00e9der au contenu complet de cette falaise en achetant ou d\u00e9bloquant les topos suivants :"
        },
        "grande-voie": {
            "buy_product": "Pour avoir toutes les informations, achetez la grande voie",
            "guidebook": "Vous pouvez acc\u00e9der au contenu complet de cette grande voie en achetant ou d\u00e9bloquant le topo suivant :",
            "guidebook_plural": "Vous pouvez acc\u00e9der au contenu complet de cette grande voie en achetant ou d\u00e9bloquant les topos suivants :"
        }
    },
    "ErrorHandler": {
        "message": "Une erreur s'est produite...",
        "not_found": "Aucun contenu n'est accessible \u00e0 cette adresse",
        "reload": "Rafra\u00eechir",
        "home": "Retourner \u00e0 l'accueil"
    },
    "InfiniteListComponent": {
        "loading": {
            "done": "Chargement termin\u00e9"
        }
    },
    "LoginComponent": {
        "title": "Se connecter",
        "subtitle": "Saisissez vos informations pour acc\u00e9der \u00e0 votre compte",
        "btn": {
            "connect": "Se connecter"
        },
        "link": {
            "register": "Cr\u00e9er un compte",
            "reset-password": "Mot de passe perdu ?"
        },
        "placeholder": {
            "email": "Email",
            "password": "Mot de passe"
        },
        "error": "Une erreur est survenue lors de la r\u00e9cup\u00e9ration des donn\u00e9es de votre compte. Veuillez r\u00e9essayer ou nous contacter si le probl\u00e8me persiste."
    },
    "FullscreenSpinnerComponent": {
        "waitingBtn": {
            "title": "Cacher l'\u00e9cran de chargement..."
        }
    },
    "RegisterScreen": {
        "title": "Cr\u00e9er un compte",
        "subtitle": "Saisissez vos informations pour cr\u00e9er un compte",
        "btn": {
            "register": "Cr\u00e9er un compte"
        },
        "before": {
            "login": "Vous \u00eates d\u00e9j\u00e0 inscrit ?"
        },
        "link": {
            "login": "Se connecter"
        },
        "form": {
            "firstName": {
                "required": "Saisissez votre pr\u00e9nom"
            },
            "lastName": {
                "required": "Saisissez votre nom"
            },
            "email": {
                "required": "Saisissez votre adresse email",
                "valid": "Saisissez une adresse email valide"
            },
            "password": {
                "required": "Saisissez un mot de passe"
            },
            "passwordConfirm": {
                "required": "Confirmez le mot de passe",
                "match": "Saisissez des mots de passe identiques"
            },
            "newsletter": {
                "accept": "Acceptez-vous de recevoir des communications de la part d'Omegaroc ?"
            },
            "cgu": {
                "url": "https:\/\/omegaroc.com\/cgu-cgv\/",
                "accept": "En cochant cette case vous acceptez les ",
                "title": "Conditions G\u00e9n\u00e9rales d'Utilisation",
                "error": "Veuillez cocher cette case."
            }
        },
        "placeholder": {
            "email": "Email",
            "password": "Mot de passe",
            "firstName": "Pr\u00e9nom",
            "lastName": "Nom",
            "passwordConfirm": "Confirmation du mot de passe"
        },
        "swal": {
            "success": {
                "title": "Bienvenue !",
                "message": "Votre compte a bien \u00e9t\u00e9 cr\u00e9\u00e9. Vous allez recevoir un email pour confirmer votre inscription.",
                "okBtn": "Ok"
            },
            "error": {
                "title": "Une erreur s'est produite",
                "okBtn": "Ok"
            }
        }
    },
    "RegisterConfirmScreen": {
        "swal": {
            "success": {
                "title": "F\u00e9licitations !",
                "message": "Vous venez de confirmer votre adresse email ! Vous pouvez \u00e0 pr\u00e9sent visualiser vos topos.",
                "okBtn": "Allons-y"
            },
            "error": {
                "title": "Attention !",
                "okBtn": "Ok"
            }
        }
    },
    "ResetPasswordScreen": {
        "title": "Mot de passe perdu",
        "subtitle": "Veuillez saisir votre email pour recevoir un lien qui vous permettra de mettre \u00e0 jour votre mot de passe.",
        "btn": {
            "reset-password": "Envoyer un email"
        },
        "link": {
            "login": "Se connecter"
        },
        "placeholder": {
            "email": "Email"
        },
        "swal": {
            "success": {
                "title": "Succ\u00e8s !",
                "message": "Vous allez recevoir un email vous indiquant la marche \u00e0 suivre pour mettre \u00e0 jour votre mot de passe.",
                "okBtn": "Ok"
            }
        }
    },
    "ResetPasswordConfirmScreen": {
        "title": "Changement de mot de passe",
        "subtitle": "Saisissez votre nouveau mot de passe",
        "btn": {
            "reset-password": "Enregistrer"
        },
        "placeholder": {
            "password": "Mot de passe",
            "passwordConfirm": "Confirmation mot de passe"
        },
        "swal": {
            "success": {
                "title": "Succ\u00e8s !",
                "message": "Votre mot de passe a \u00e9t\u00e9 mis \u00e0 jour ! Vous pouvez retourner \u00e0 vos topos.",
                "okBtn": "Ok"
            }
        }
    },
    "Omegaroc": {
        "logo": "OmegaRoc",
        "collapse": {
            "open": "Lire la suite",
            "close": "Fermer"
        },
        "popover": {
            "last_update": "Mise \u00e0 jour du site depuis le topo papier ou nouveau site hors topo",
            "multipitch_last_update": "Grande voie mise \u00e0 jour depuis le topo papier ou nouvelle grande voie hors topo"
        }
    },
    "searchResults": {
        "avatar": {
            "cliff": "Fa",
            "multipitch": "GV",
            "mixte": "Fa\/GV"
        },
        "routes": "Voies",
        "grades": {
            "cliff": "Cotation min. \/ Cotation max.",
            "multipitch": "Cotation max \/ Cotation oblig"
        },
        "lengthMax": "max",
        "lengthMaxHelp": {
            "cliff": "Hauteur Max. des voies",
            "multipitch": "Longueur de la voie"
        },
        "length": "m",
        "difficulty": {
            "help": "Difficult\u00e9 \/ Type d'\u00e9quipement<br\/>Eq1\/Eq2 : Terrain aventure<br\/>Eq3\/Eq4 : Equip\u00e9"
        }
    },
    "Prix : ": "Prix :",
    "AddressUpdateComponent": {
        "title": "Modifier une adresse",
        "subtitle": "Saisissez vos informations"
    },
    "AddressCreateComponent": {
        "title": "Ajouter une adresse",
        "subtitle": "Saisissez vos informations",
        "placeholder": {
            "firstName": "Pr\u00e9nom",
            "lastName": "Nom",
            "businessName": "Raison sociale (facultatif)",
            "address": "Adresse",
            "zipcode": "Code postal",
            "city": "Ville",
            "countryCode": "Pays",
            "country": "Pays",
            "phone": "Téléphone"
        },
        "help": {
            "phone": "Le numéro de téléphone peut-être utilisé pour la livraison"
        },
        "btn": {
            "save": "Enregistrer",
            "back": "Retour"
        },
        "form": {
            "address": {
                "required": "Saisissez votre num\u00e9ro et rue"
            },
            "zipcode": {
                "required": "Saisissez votre code postal"
            },
            "city": {
                "required": "Saisissez votre ville"
            },
            "countryCode": {
                "required": "S\u00e9lectionnez un pays"
            },
            "country": {
                "required": "S\u00e9lectionnez un pays"
            },
            "phone": {
                "required": "Saisissez votre numéro de téléphone"
            }
        }
    },
    "UserInformationUpdateComponent": {
        "alert": {
            "success": "Vos informations ont \u00e9t\u00e9 mises \u00e0 jour"
        }
    },
    "PasswordUpdateComponent": {
        "title": "Modifier votre mot de passe",
        "subtitle": "Saisissez votre nouveau mot de passe",
        "placeholder": {
            "currentPassword": "Mot de passe actuel",
            "password": "Nouveau mot de passe",
            "passwordConfirm": "Confirmation du mot de passe"
        },
        "btn": {
            "save": "Enregistrer",
            "back": "Retour"
        },
        "alert": {
            "success": "Votre mot de passe a \u00e9t\u00e9 mis \u00e0 jour"
        },
        "form": {
            "currentPassword": {
                "required": "Saisissez votre mot de passe actuel"
            },
            "password": {
                "required": "Saisissez votre nouveau mot de passe"
            },
            "passwordConfirm": {
                "required": "Confirmez votre nouveau mot de passe"
            }
        }
    },
    "CartScreen": {
        "item": {
            "isNotVirtual": "Livre",
            "subtitle": {
                "guidebook": "Topo papier",
                "guidebook_virtual": "Topo numérique",
                "cliff": "Site - Topo papier",
                "cliff_virtual": "Site - Topo numérique",
                "multipitch": "GV - Topo papier",
                "multipitch_virtual": "GV - Topo numérique"
            }
        }
    },
    "BillingComponent": {
        "btn": {
            "continue": "Continuer",
            "back": "Retour au panier"
        }
    },
    "common": {
        "add": "Ajouter",
        "back": "Retour",
        "cancel": "Annuler",
        "confirm": "Confirmer",
        "error": {
            "save": "Erreur lors de la mise \u00e0 jour",
            "delete": "Erreur lors de la suppression",
            "create": "Erreur lors de la cr\u00e9ation",
            "update": "Erreur lors de la modification",
            "logout": "Erreur lors de la d\u00e9connexion"
        },
        "date_placeholder": "JJ/MM/AAAA"
    },
    "Order": {
        "marking": {
            "completed": "Termin\u00e9e",
            "cancelled": "Annul\u00e9e"
        }
    },
    "seo": {
        "grade": "d'une cotation {{grade}}",
        "grades": "dans des cotations variant de {{minGrade}} \u00e0 {{maxGrade}}",
        "title": {
            "topos": "Topo d\u2019escalade OmegaRoc - Tous les topos",
            "search": "Topo d\u2019escalade OmegaRoc - Recherche",
            "myroute": "Topo d\u2019escalade OmegaRoc - Mes voies",
            "guidebook": "Topo d'escalade {{label}} - OmegaRoc",
            "cliff": "Topo d\u2019escalade de la falaise {{label}} - {{region}} - OmegaRoc",
            "multi_pitch_route": "Topo d\u2019escalade de la grande voie {{label}} - {{region}} - OmegaRoc",
            "sector_route": "Topo d\u2019escalade de {{label}}, site de {{cliff}} - OmegaRoc"
        },
        "description": {
            "topos": "Tous les topos d'escalade disponibles sur l'application OmegaRoc",
            "search": "Tous les sites d'escalade et les grandes voies disponibles sur l'application OmegaRoc",
            "myroute": "Toutes mes voies sur l'application OmegaRoc",
            "guidebook": "Description {{label}} {{description}} - OmegaRoc",
            "cliff": "Toutes les informations d\u00e9taill\u00e9es et le topo num\u00e9rique du site d'escalade {{label}} comportant {{routeCount}} voies {{grades}}",
            "multi_pitch_route": "Topo num\u00e9rique de la grande voie {{label}} d'une hauteur de {{length}} et d'une difficult\u00e9 de {{difficulty}} {{maxGrade}}",
            "sector_route": "Toutes les informations d\u00e9taill\u00e9es et le topo num\u00e9rique du secteur {{label}} sur le site de {{cliff}} comportant  {{routeCount}} voies {{grades}}"
        }
    },
    "UpdatePWAComponent": {
        "updateText": "Mise \u00e0 jour disponible",
        "help": {
            "install": "Blabla",
            "button": "Ce message ne disparait pas ?",
            "title": "Aide pour la mise \u00e0 jour de l'application",
            "description": "Une nouvelle version de l'application est disponible. Cependant elle n'est pas utilis\u00e9e sur votre \u00e9quipement. Pour profiter des derni\u00e8res fonctionnalit\u00e9s, vous avez deux possibilit\u00e9s :",
            "pwa": "Naviguer via l'application. Vous pouvez l'activer en cliquant ici.",
            "update-navigator": "Mettre \u00e0 jour votre navigateur. Pour cela, vous devez fermer tous les onglets ouverts d'Omegaroc et appuyer sur le bouton `{{button}}`"
        }
    }
}

export default fr;